/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "antd/dist/reset.css";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import Script from "next/script";
import "nprogress/nprogress.css";
import { useEffect } from "react";
import { CURRENT_VERSION, getMyBaseInfo } from "../lib/util";
import "../styles/global.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 5, // 5 minutes
      staleTime: 1000 * 60 * 2, // 1 minute
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    getMyBaseInfo();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const res = await fetch("/api/version");
        const data = await res.json();

        if (
          data &&
          typeof data.version === "string" &&
          data.version !== CURRENT_VERSION
        ) {
          // eslint-disable-next-line no-console
          console.log("> New version available, reloading page", {
            dataVersion: data.version,
            currentVersion: CURRENT_VERSION,
          });
          window.location.reload();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Failed to check for updates", error);
      }
    }, 900_000); // Check every 900 seconds / 15 minutes

    return () => clearInterval(interval);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider session={pageProps.session}>
        <Component {...pageProps} />
      </SessionProvider>
      <Script id="clarity-script" strategy="afterInteractive">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID}");
        `}
      </Script>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
      />
      <Script id="gtm-script" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
      </Script>
    </QueryClientProvider>
  );
}
