import { ClientError, GraphQLClient } from "graphql-request";
import { graphql } from "../__generated__/gql";

// This is used to force the browser to reload the page when the version changes.
export const CURRENT_VERSION = "1.2.5";

export const graphqlClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_API_BASE}/graphql`,
  {
    credentials: "include",
    mode: "cors",
  },
);

export function errorToStringUnfiltered(e: unknown): string {
  if (e instanceof ClientError)
    return (
      e.response.errors?.[0]?.message ??
      `GraphQL Error (Code: ${e.response.status})`
    );
  if (e instanceof Error) return e.message;
  if (
    typeof e === "object" &&
    e &&
    "message" in e &&
    typeof e.message === "string"
  )
    return e.message;
  return "Unknown Error";
}

export function errorToString(e: unknown): string {
  const errorString = errorToStringUnfiltered(e);
  if (errorString.toLowerCase().includes("econnreset"))
    return "Please retry soon (Connection Reset)";
  if (errorString.toLowerCase().includes("network error"))
    return "Please retry soon (Network Error)";
  if (errorString.toLowerCase().includes("failed to fetch"))
    return "Please retry soon (Failed to Fetch)";
  return errorString;
}

const WhoamiBaseDocument = graphql(`
  query WhoamiBase {
    whoami {
      id
      firstName
      lastName
      emailAddress
    }
  }
`);

// eslint-disable-next-line max-len
export async function getMyBaseInfo(): Promise<{
  id: string;
  emailAddress: string;
} | null> {
  const r1 = await graphqlClient.request(WhoamiBaseDocument);
  return r1.whoami || null;
}

const GRADE_NAMES: Record<number, string> = {
  [-10]: "",
  [-1]: "Pre-Kindergarten",
  0: "Kindergarten",
  1: "1st Grade",
  2: "2nd Grade",
  3: "3rd Grade",
  4: "4th Grade",
  5: "5th Grade",
  6: "6th Grade",
  7: "7th Grade",
  8: "8th Grade",
  9: "9th Grade",
  10: "10th Grade",
  11: "11th Grade",
  12: "12th Grade",
};

export function intToGradeFullName(gradeInt: number): string {
  return GRADE_NAMES[gradeInt] || gradeInt.toString();
}

export function intToGrade(gradeInt: number): string {
  if (gradeInt === 0) return "K";
  if (gradeInt === -1) return "TK";
  if (gradeInt === -2) return "PS";
  if (gradeInt === -10) return "";
  return gradeInt.toString();
}

/**
 * Naming convention for the keys:
 * release-<feature>: to control the release of a feature, meant to be short lived
 * access-<feature>: to control access to functionality
 * custom-<flag>: to control access to custom functionality (like for SAUSD)
 */
export const allFeatureFlags = ["access-bulkCheckout", "custom-sausd"] as const;

type FeatureFlag = (typeof allFeatureFlags)[number];

function getLocalStorageFlags(key: string): string[] {
  if (typeof window === "undefined") return [];
  const flags = localStorage.getItem(key);
  return flags ? flags.split(",").filter(Boolean) : [];
}

export function hasFeatureFlag(featureFlag: FeatureFlag): boolean {
  if (getLocalStorageFlags("featureFlags").includes(featureFlag)) {
    return true;
  }
  if (getLocalStorageFlags("featureFlagsDisabled").includes(featureFlag)) {
    return false;
  }
  const environmentFeatureFlags =
    process.env.NEXT_PUBLIC_FEATURE_FLAGS?.split(",") ?? [];
  return environmentFeatureFlags.includes(featureFlag);
}

export const localDateTime = (isoString: string) => {
  const date = new Date(isoString);
  return date.toLocaleString();
};

export const localDate = (isoString: string) => {
  const date = new Date(isoString);
  return date.toLocaleDateString();
};

export const localTime = (isoString: string) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
};

export const calculateDuration = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return endDate.getTime() - startDate.getTime();
};

export const formatDuration = (start: string, end: string) => {
  const diff = calculateDuration(start, end);
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor(diff / 1000 / 60) - hours * 60;
  return `${hours}h ${minutes}m`;
};

export const userDisplayName = (
  user:
    | {
        firstName: string;
        lastName: string;
        emailAddress?: string | null;
      }
    | null
    | undefined,
) => {
  if (!user) {
    return "";
  }
  if (!user.firstName || !user.lastName) {
    return user.emailAddress || "";
  }
  return `${user.firstName} ${user.lastName}`;
};
